import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestoreDB } from "../config";

const useFactoryLocations = () => {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    const fetchPlaces = async () => {
      const querySnapshot = await getDocs(collection(firestoreDB, "pinCodes"));
      const placesData = [];
      querySnapshot.forEach((doc) => {
        if (doc?.data()?.place) {
             placesData.push(doc.data().place);
          }
        // doc?.data()?.place && placesData.push(doc.data().place);
      });
      setPlaces(placesData);
    };

    fetchPlaces();
  }, []);

  return places;
};

export default useFactoryLocations;
