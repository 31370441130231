import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Button,
  Card,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { addDoc, collection, doc,updateDoc } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { toast } from 'react-toastify';
// hooks
import useFetchBrickPrices from '../../hooks/useFetchBrickPrices';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListToolbar, UserListHead } from '../../sections/@dashboard/user';
import { firestoreDB } from '../../config';
// utils
import { getComparator, applySortFilter } from '../../utils/index';
import { BRICKS_QUALITY, BRICKS_TYPES } from '../../utils/constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'brickPrice', label: 'Brick Price', alignRight: false },
  { id: 'brickQuality', label: 'Brick Quality', alignRight: false },
  { id: 'brickSize', label: 'Brick Size', alignRight: false },
  { id: 'brickType', label: 'Brick Type', alignRight: false },
  { id: 'bricksPerTon', label: 'Bricks Per Ton', alignRight: false },
  { id: 'weightPerBrick', label: 'Weight Per Brick', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

export default function BrickPricesPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('fullName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [brickPricesData] = useFetchBrickPrices(setIsLoading);
  const [brickPrices, setBrickPrices] = useState([]);

  useEffect(() => {
    if (brickPricesData?.length > 0) {
      setBrickPrices(brickPricesData);
    }
  }, [brickPricesData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - brickPrices?.length) : 0;

  const filteredBrickPrices = applySortFilter(brickPrices, getComparator(order, orderBy), filterName, 'fullName');

  const isNotFound = !filteredBrickPrices.length && !!filterName;

  const handleView = (id) => {
    navigate(`/dashboard/brickPrices/${id}`);
  };

  const handleAdd = () => {
    navigate('/dashboard/brickPrices/new');
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const docRef = doc(firestoreDB, 'brickPrices', id);
      await updateDoc(docRef, {
        isDeleted: true,
        updatedAt: new Date(),
      });
      const updatedBrickPrices = brickPrices.filter((brickPrice) => brickPrice.id !== id);
      setBrickPrices(updatedBrickPrices);
      toast.success('BrickPrice deleted successfully');
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to duplicate a row
  const handleDuplicate = async (id) => {
    const selectedRow = brickPrices.find((row) => row.id === id);
  
    if (selectedRow) {
      // Clone the selected row without the 'id' field
      const { id, ...duplicatedRow } = selectedRow;
  
      try {
        setIsLoading(true);
        const brickPricesRef = collection(firestoreDB, 'brickPrices');
        const newDocRef = await addDoc(brickPricesRef, {
          ...duplicatedRow,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
        });
        const newId = newDocRef.id;
  
        // Add the duplicated row to the state
        setBrickPrices([...brickPrices, { id: newId, ...duplicatedRow }]);
  
        toast.success('BrickPrice duplicated successfully');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  };
  

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Brick Prices
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd}>
            New Brick Price
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}>
            {/* <StatusFilter
              setOpenFilter={setOpenFilter}
              setAnchorEl={setAnchorEl}
              Boolean={Boolean}
              openFilter={openFilter}
              anchorEl={anchorEl}
              currentStatus={currentStatus}
              setCurrentStatus={setCurrentStatus}
            /> */}
          </UserListToolbar>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={brickPrices.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {brickPrices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, brickPrice, brickQuality, brickSize, brickType, bricksPerTon, weightPerBrick } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">{brickPrice} &#8377;</TableCell>
                        <TableCell align="left">{BRICKS_QUALITY[brickQuality]}</TableCell>
                        <TableCell align="left">{brickSize} Inch</TableCell>
                        <TableCell align="left">{BRICKS_TYPES[brickType]}</TableCell>
                        <TableCell align="left">{bricksPerTon}</TableCell>
                        <TableCell align="left">{weightPerBrick} KG</TableCell>
                        <TableCell align="left">
                          <Tooltip title="View" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleView(id)}>
                              <Iconify icon="typcn:eye" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleDelete(id)}>
                              <Iconify icon={'ic:baseline-delete'} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Duplicate" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleDuplicate(id)}>
                              <Iconify icon="eva:copy-fill" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {brickPrices?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={brickPrices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
