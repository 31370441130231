import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import * as JSPDF from 'jspdf';

function HtmlToPdfConverter() {
  const contentRef = useRef(null);

  const generatePdf = () => {
    if (contentRef.current) {
      const input = contentRef.current;

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JSPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
        pdf.save('downloaded.pdf');
      });
    }
  };

  return (
    <div>
      <button onClick={generatePdf}>Convert to PDF</button>
      <div ref={contentRef}>
        {/* Your HTML content that you want to convert to PDF */}
        <h1>My HTML Content</h1>
        <p>This is a sample content for conversion to PDF.</p>
      </div>
    </div>
  );
}

export default HtmlToPdfConverter;
