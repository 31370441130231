// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { formatNumberWithCommas } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppTotalAmount.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  secondTitle: PropTypes.string,
  secondTotal: PropTypes.number || PropTypes.string,
  thirdTitle: PropTypes.string,
  thirdTotal: PropTypes.number || PropTypes.string,
};

export default function AppTotalAmount({
  title,
  total,
  secondTitle,
  secondTotal,
  thirdTitle,
  thirdTotal,
  icon,
  color = 'primary',
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        pt: 5,
        pb: 2,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>
      <div>
        <Typography variant="h6">{formatNumberWithCommas(total) || 0}</Typography>

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <div>
          <Typography variant="span">{formatNumberWithCommas(secondTotal) || 0}</Typography>

          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {secondTitle}
          </Typography>
        </div>
        <div>
          <Typography variant="span">{(thirdTotal < 0 ? `(${formatNumberWithCommas(thirdTotal)})` : formatNumberWithCommas(thirdTotal)) || 0}</Typography>

          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {thirdTitle}
          </Typography>
        </div>
      </div>
    </Card>
  );
}
