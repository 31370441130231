import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import HtmlToPdfConverter from '../components/HtmlToPdfConverter';
import Loader from '../components/Loader';
import useFactoryLocations from '../hooks/useFactoryLocations';
import usePerkmrates from '../hooks/usePerkmrates';
import useWheelsData from '../hooks/useWheelsData';
import useFetchBrickPrices from '../hooks/useFetchBrickPrices';
import '../style.css';
import { TRUCK_TYPES } from '../utils/constants';

const TransportPage = () => {
  const [formData, setFormData] = useState({
    factoryLocation: 'jaipur',
    destinationPincode: '',
    vehicleType: '12',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [locationdiff, setLocationdiff] = useState(0);
  const [finalResult, setFinalResult] = useState({});
  const factoryLocationOptions = useFactoryLocations();
  const perkmRates = usePerkmrates();
  const wheelsData = useWheelsData();
  const [brickPricesData] = useFetchBrickPrices(setLoading);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const convertValue = (value) => {
    console.log({ value });
    const priceArr = JSON.stringify(value).split('.');
    let finalPrice = 0;
    console.log('priceArr >>>', priceArr);
    if (parseInt(priceArr[1], 10) > 222) {
      finalPrice = (Math.floor(priceArr[0]) + 0.88).toFixed(2);
    } else if (parseInt(priceArr[1], 10) < 222) {
      finalPrice = (Math.floor(priceArr[0]) - 1 + 0.99).toFixed(2);
    } else if (parseInt(priceArr[1], 10) > 888) {
      finalPrice = (Math.floor(priceArr[0]) + 0.99).toFixed(2);
    }
    // Return a default value (you can change this to suit your needs)
    return finalPrice;
  };

  const handleCalculateDistance = async () => {
    try {
      const origin = 302039;
      const destination = 302017;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=AIzaSyB6dc6t3EtwxN729vRDPCwl0u4KJHNZsXc`
      );
      const data = await response.json();
      console.log('data >>>', data);
      const result = data.rows[0].elements[0].distance.text;
      // res.json({ distance: result });
    } catch (error) {
      console.error('Error:', error);
      // res.status(500).json({ error: 'An error occurred while calculating distance.' });
    }
    //   const origin = 302039;
    //   const destination = 302017;
    //   fetch(
    //     `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=AIzaSyB6dc6t3EtwxN729vRDPCwl0u4KJHNZsXc`
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {

    //       console.log('data >>>', data);
    //       const result = data.rows[0].elements[0].distance.text;
    //       setLocationdiff(result);
    //     })
    //     .catch((error) => console.error('Error:', error));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.factoryLocation) {
      newErrors.factoryLocation = 'Factory Location is required';
    }

    if (!formData.destinationPincode) {
      newErrors.destinationPincode = 'Destination Pincode is required';
    }

    if (!formData.vehicleType) {
      newErrors.vehicleType = 'Vehicle Type is required';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleCalculateDistance();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    const getBrickTonPrice = (size, type) => {
      const bricksQty = brickPricesData.find((brick) => brick.brickSize === size && brick.brickType === type);
      return bricksQty ? bricksQty.bricksPerTon : null;
    };

    try {
      let distance = 510; // Updated initial distance
      distance += 10;
      const selectedVehicleType = formData.vehicleType;

      const perKmRateData =
        perkmRates.filter(
          (rate) =>
            rate.distanceRangeFrom <= distance &&
            rate.distanceRangeTo >= distance 
        ) || {};
      console.log('perKmRateData >>>', perKmRateData);
      const perkmPrice = parseInt(perKmRateData.per_km_price, 10) || 0;
      const margin = 5;
      const brickRate = Math.round(perkmPrice + perkmPrice * (margin / 100));
      const totalBrickRate = brickRate * distance;
      console.log('brickRate >>', brickRate);
      const wheel = wheelsData.find((wheel) => wheel.truckType.toString() === selectedVehicleType);
      const capacity = wheel?.capacity || 0;

      const bricksQty8Inch = getBrickTonPrice('8', 'interlock');
      const bricksQty6Inch = getBrickTonPrice('6', 'interlock');

      const perTonCost = Math.round(totalBrickRate / parseInt(capacity, 10));

      const calculateBrickRates = (size) => ({
        eightInch: (perTonCost / size).toFixed(1),
        sixInch: (perTonCost / size).toFixed(1),
      });

      const allBricksRate = {
        Standard: calculateBrickRates(bricksQty8Inch),
        Prime: calculateBrickRates(bricksQty6Inch),
        Super: calculateBrickRates(bricksQty6Inch),
      };
      console.log('perTonCost >>>', perTonCost);
      const transportCostEightInch = Math.ceil((perTonCost / bricksQty8Inch) * 10) / 10;
      const transportCostSixInch = Math.ceil((perTonCost / bricksQty6Inch) * 10) / 10;

      const brickPriceObj = brickPricesData.reduce(
        (result, item) => {
          if (item.brickQuality === 'standard' || item.brickQuality === 'prime' || item.brickQuality === 'super') {
            const key = `${item.brickQuality[0].toUpperCase()}${item.brickQuality.slice(1)}`;

            const size = item.brickSize === '8' ? 'eightInch' : 'sixInch';
            result[key][size] = parseInt(item.brickPrice, 10) || 0;
          }
          return result;
        },
        {
          Standard: { eightInch: 0, sixInch: 0 },
          Prime: { eightInch: 0, sixInch: 0 },
          Super: { eightInch: 0, sixInch: 0 },
        }
      );
      const applyGST = (value) => value + value * 0.12;

      console.log('brickPriceObj>>>', brickPriceObj);
      console.log('transportCostEightInch>>>', transportCostEightInch);
      console.log('transportCostSixInch>>>', transportCostSixInch);
      const finalRateObjWithGST = {
        Standard: {
          eightInch: convertValue(applyGST(transportCostEightInch + brickPriceObj.Standard.eightInch)),
          sixInch: convertValue(applyGST(transportCostSixInch + brickPriceObj.Standard.sixInch)),
        },
        Prime: {
          eightInch: convertValue(applyGST(transportCostEightInch + brickPriceObj.Prime.eightInch)),
          sixInch: convertValue(applyGST(transportCostSixInch + brickPriceObj.Prime.sixInch)),
        },
        Super: {
          eightInch: convertValue(applyGST(transportCostEightInch + brickPriceObj.Super.eightInch)),
          sixInch: convertValue(applyGST(transportCostSixInch + brickPriceObj.Super.sixInch)),
        },
      };
      setFinalResult(finalRateObjWithGST);
    } catch (error) {
      console.error('Calculation Error:', error);
      setErrors({ calculation: 'An error occurred during the calculation.' });
    } finally {
      setLoading(false);
    }
  };

  const BrickSizeTable = ({ type, data }) => {
    return (
      <>
        {/* <HtmlToPdfConverter /> */}
        {data?.[type] ? (
          <div className="brick-type">
            <h3>{type}</h3>
            <div className="brick-sizes">
              <div className="brick-size" key="eightInch">
                <p>8 Inch:</p>
                <p>{data?.[type]?.eightInch || 0} &#8377;</p>
              </div>
              <div className="brick-size" key="sixInch">
                <p>6 Inch:</p>
                <p>{data?.[type]?.sixInch || 0} &#8377;</p>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="form-container">
      <Loader loading={loading} />
      <h2>Transport Cost Form</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="factoryLocation">
          <Form.Label>Factory Location</Form.Label>
          <Form.Select
            aria-label="Factory Location"
            name="factoryLocation"
            value={formData.factoryLocation}
            onChange={handleChange}
          >
            <option value="">Select Location</option>
            {factoryLocationOptions.map((type) => (
              <option key={type} value={type}>
                {type?.toUpperCase()}
              </option>
            ))}
          </Form.Select>
          {errors.factoryLocation && <div className="error-message">{errors.factoryLocation}</div>}
        </Form.Group>

        <Form.Group controlId="destinationPincode">
          <Form.Label>Destination Pincode</Form.Label>
          <Form.Control
            type="text"
            name="destinationPincode"
            value={formData.destinationPincode}
            onChange={handleChange}
            placeholder="Enter Pincode"
          />
          {errors.destinationPincode && <div className="error-message">{errors.destinationPincode}</div>}
        </Form.Group>

        <Form.Group controlId="vehicleType">
          <Form.Label>Vehicle Type</Form.Label>
          <Form.Select
            aria-label="Vehicle Type"
            name="vehicleType"
            value={formData.vehicleType}
            onChange={handleChange}
          >
            <option value="">Select Vehicle Type</option>
            {TRUCK_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
          {errors.vehicleType && <div className="error-message">{errors.vehicleType}</div>}
        </Form.Group>

        <Button variant="primary" type="submit">
          Get Quotation
        </Button>
      </Form>

      <div className="brick-rates">
        <BrickSizeTable type="Standard" data={finalResult} />
        <BrickSizeTable type="Super" data={finalResult} />
        <BrickSizeTable type="Prime" data={finalResult} />
      </div>
    </div>
  );
};

export default TransportPage;
